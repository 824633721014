<template>
  <div id='user-view'>
    <v-row>
      <v-col cols='12' lg='5'>
        <v-card :loading='leftBlockLoading'>
          <v-card-actions class='py-2 pl-2 pr-0'>
            <v-chip
              v-if='!leftBlockLoading'
              color='error'
              label
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text ml-1`"
            >
              {{ orderId }}
            </v-chip>

            <v-spacer></v-spacer>
            <span v-if='leftBlockLoading' class='my-2'>
              {{ $t('data_loading') }}
            </span>
            <v-spacer></v-spacer>
            <PopupCustomer v-if='!leftBlockLoading' class='pt-1' :customer-data='customerData' />
          </v-card-actions>
          <v-divider></v-divider>
          <v-tabs v-model='tab' class='user-tabs' grow centered show-arrows>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiPill }}
              </v-icon>
              <span>{{ $t('drug_equipment') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiAutoFix }}
              </v-icon>
              <span>{{ $t('course') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiStethoscope }}
              </v-icon>
              <span>{{ $t('check') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiCardAccountDetailsStarOutline }}
              </v-icon>
              <span>{{ $t('memberCard') }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items id='user-tabs-content' v-model='tab'>
            <v-tab-item>
              <DrugList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                :status-delete='statusDelete'
                :customer-id='String(customerData.customer_id_pri)'
                :customerDrugList='customerDrugList'
                @onAdd='getCartList'
              />
            </v-tab-item>
            <v-tab-item>
              <CourseList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                :customer-id='String(customerData.customer_id_pri)'
                @onAdd='getCartList'
              />
            </v-tab-item>
            <v-tab-item>
              <CheckList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                :customer-id='String(customerData.customer_id_pri)'
                @onAdd='getCartList'
              />
            </v-tab-item>
            <v-tab-item>
              <MembetList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                :customer-id='String(customerData.customer_id_pri)'
                @onAdd='getCartList'
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col cols='12' lg='7'>
        <v-card class='mb-6'>
          <v-card-actions class='pt-2 pb-2 px-2'>
            <v-dialog ref='datePicker' v-model.trim='isShowDate' :return-value.sync='date_create'
                      width='290px'>
              <template v-slot:activator='{ on, attrs }'>
                <v-text-field
                  v-model.trim='date_create'
                  :label="$t('date')"
                  v-bind='attrs'
                  :prepend-inner-icon='icons.mdiCalendar'
                  outlined
                  dense
                  hide-details
                  style='max-width: 250px'
                  v-on='on'
                ></v-text-field>
              </template>
              <v-date-picker v-model.trim='date_create' class='set-font' :locale='$i18n.locale'
                             scrollable>
                <v-spacer></v-spacer>
                <v-btn text color='primary' @click='$refs.datePicker.save(date_create)'>
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn text color='secondary' @click='isShowDate = false'>
                  {{ $t('cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
            <v-spacer></v-spacer>
            <v-btn color='primary' outlined :loading='loadingGetOld' @click='getDrugOld'>
              {{ $t('drougOld') }}
            </v-btn>
          </v-card-actions>
          <v-data-table
            :headers='columns'
            :items='cartList'
            :options.sync='options'
            :loading='cartLoading'
            mobile-breakpoint='0'
            dense
            fixed-header
            height='300'
            disable-sort
            hide-default-footer
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.id`]='{ index }'>
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.checking_id`]='{ item }'>
              {{ (item.checking_id ? item.orderdetail_temp_cost : item.orderdetail_temp_price) | formatPrice
              }}
              <!--              {{ item.orderdetail_temp_price | formatPrice }} อันเดิม ที่พี่เอสั่งแก้ -->
            </template>
            <template v-slot:[`item.orderdetail_temp_discount`]='{ item }'>
              {{ item.orderdetail_temp_discount | formatPrice }}
            </template>
            <template v-slot:[`item.orderdetail_temp_name`]='{ item }'>
              <div v-if='item.orderdetail_temp_name_set'>
                <v-chip
                  :color='item.drug_id_pri?`primary`:item.checking_id?`success`:`info`'
                  :class=" item.drug_id_pri?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:item.checking_id?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
                  small
                >
                  <span>
                    {{ item.drug_id_pri ? $t('setDrug') : item.checking_id ? $t('checkingSet') : $t('courseSet')
                    }}
                  </span>
                </v-chip>
                {{ item.orderdetail_temp_name }}
              </div>
              <span v-else>
                {{ item.orderdetail_temp_name }}
              </span>
            </template>
            <template v-slot:[`item.orderdetail_temp_total`]='{ item }'>
              {{ item.orderdetail_temp_total | formatPrice }}
            </template>
            <template v-slot:[`item.actions`]='{ item }'>
              <v-tooltip v-if='item.drug_id_pri' color='#212121' top class='p-0 m-0'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn
                    icon
                    v-bind='attrs'
                    color='primary'
                    small
                    @click='
                      dataEdit = item
                      isEditCartDrug = true
                    '
                    v-on='on'
                  >
                    <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('edit') }}</span>
              </v-tooltip>
              <v-tooltip color='#212121' top class='p-0 m-0'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn
                    icon
                    color='error'
                    v-bind='attrs'
                    :loading='removeLoading'
                    :disabled='removeLoading'
                    small
                    @click='removeCart(item.orderdetail_temp_id_pri)'
                    v-on='on'
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card :loading='cartLoading || sumLoading' min-height='304'>
          <v-row class='px-2'>
            <v-col cols='12' md='6'>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('total_price') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ totalPrice | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='4'>
                  <span class='font-weight-semibold'> {{ $t('discount') }} </span>
                </v-col>
                <v-col cols='8'>
                  <div class='d-flex justify-content-end'>
                    <v-text-field
                      v-model='discount'
                      dense
                      type='number'
                      style='width: 100px'
                      hide-details
                      outlined
                      @input='calculateDiscount'
                    >
                      <template v-slot:label>
                        <span class='error--text'>{{ $t('max_reduction') }} {{ limitDisCount
                          }} %</span>
                        <span v-if='discountStatus == 1'
                              class='error--text'> ({{ $t('Group Customer') }}) </span>
                        <span v-if='discountStatus == 2' class='error--text'> ({{ $t('Member Card')
                          }}) </span>
                      </template>
                    </v-text-field>
                    <v-select
                      v-model='discountType'
                      dense
                      hide-details
                      :label="$t('discount_type')"
                      style='width: 80px'
                      outlined
                      item-text='label'
                      item-value='value'
                      :items='discountTypeList'
                      @input='discountTypeChenage'
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row align='center' justify='center' v-if='listCoupon.length'>
                <v-col cols='6' class='d-flex align-center' style='white-space: nowrap'>
                  <span class='font-weight-semibold me-2'> {{ $t('cashbackValue') }}</span>
                  <v-img src='@/assets/images/transaction.svg' style='cursor: pointer'
                         @click='isShowCashbackList = true'
                         class='me-2'
                         contain max-width='30'></v-img>
                </v-col>
                <v-col cols='6' class='text-right'>
                  <span>
                    {{ coupon_amount }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                  </span>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('after_discount') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'>
                  {{ totalAfterDiscount | formatPrice }}
                  {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='4'>
                  <span class='font-weight-semibold'> {{ $t('tax_type') }}</span>
                </v-col>
                <v-col cols='8' class='text-right'>
                  <v-select
                    v-model='taxType'
                    dense
                    outlined
                    :label="$t('selectVatType')"
                    :placeholder="$t('selectVatType')"
                    hide-details
                    :items='taxTypeList'
                    item-value='value'
                    :item-text='$i18n.locale'
                    @change='calculateTax'
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('vat') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ vat | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}</v-col>
              </v-row>
            </v-col>
            <v-divider vertical class='d-none d-md-inline'></v-divider>
            <v-col cols='12' md='6'>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'>{{ $t('total_value') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ totalValue | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <u>
                    <span class='font-weight-semibold'> {{ $t('total_paid') }}</span>
                  </u>
                </v-col>
                <v-col cols='6' class='text-right primary--text'>
                  <u> {{ totalPay | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}</u>
                </v-col>
              </v-row>
              <v-row no-gutters class='mt-2'>
                <v-col cols='12' class='my-1 px-1'>
                  <v-btn
                    color='info'
                    outlined
                    :disabled='totalPay < 0 || !cartList.length || processing'
                    block
                    :loading='processing'
                    @click='saveDraft'
                  >
                    {{ $t('save_the_draft') }}
                  </v-btn>
                </v-col>

                <v-col cols='12' class='my-1 px-1'>
                  <v-btn
                    color='warning'
                    block
                    :disabled='totalPay < 0 || !cartList.length || processing'
                    :loading='processing'
                    @click='deposit'
                  >
                    {{ $t('deposit') }}
                  </v-btn>
                </v-col>
                <v-col cols='12' class='my-1 px-1'>
                  <v-btn
                    color='primary'
                    block
                    :disabled='totalPay < 0 || !cartList.length || processing'
                    :loading='processing'
                    @click='payment'
                  >
                    {{ $t('pay_for_service') }}
                  </v-btn>
                </v-col>
                <v-col cols='12' class='my-1 px-1'>
                  <v-btn
                    :loading='processing'
                    :disabled='totalPay < 0 || !cartList.length || processing'
                    color='#002cc9'
                    block
                    class='white--text'
                    v-if="paySolutionsStatus == '1'"
                    @click='isPaysolutions = true'
                  >{{ $t('payWithPaySulotion') }}
                  </v-btn>
                  <!-- <PayWithPaySulotion
                    v-model="isConfirmPatment"
                    @paysolution="createOrder"
                    :customerData="customerData"
                    :statusCreateOrder="statusCreateOrder"
                    :newId="String(newId)"
                  /> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model='isDeposit' persistent max-width='450'>
      <v-card>
        <v-card-title class='text-subtitle-1'>
          <span>{{ $t('deposit') }}</span>
          <v-spacer></v-spacer>
          <span class='primary--text text-decoration-underline'>{{ totalPay | formatPrice }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  <span class='font-weight-semibold'> {{ $t('amount_baht') }}</span>
                </v-col>
                <v-col cols='7'>
                  <v-text-field v-model='depositAmount' outlined hide-details reverse dense
                                type='number'>
                    <template v-slot:prepend>
                      <v-icon color='primary'
                              @click='depositAmount > 0 ? depositAmount-- : (depositAmount = 0)'>
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color='primary' @click='depositAmount++'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  <span class='font-weight-semibold'>{{ $t('channel_pay') }} </span>
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='channelPaySelect'
                    :items='channelPayList'
                    dense
                    hide-details
                    outlined
                    item-text='shop_bank_name'
                    item-value='shop_bank_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  <span class='font-weight-semibold'> {{ $t('staff_team') }}</span>
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    dense
                    hide-details
                    outlined
                    item-text='teams_name'
                    item-value='teams_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='3'>
                  <span class='font-weight-semibold'> {{ $t('note') }}</span>
                </v-col>
                <v-col cols='9'>
                  <v-textarea
                    v-model='order_comment'
                    :rows='3'
                    hide-details
                    :placeholder="$t('note')"
                    outlined
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='processingDeposit'
            :disabled='depositAmount <= 0 || processingDeposit || depositAmount >= totalPay'
            @click='depositConfirm'
          >
            {{ $t('confirm') }}
          </v-btn>

          <v-btn
            color='secondary'
            outlined
            :loading='processingDeposit'
            :disabled='processingDeposit'
            @click='isDeposit = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='isPayment' persistent max-width='450'>
      <v-card>
        <v-card-title class='text-subtitle-1'>
          <v-radio-group v-model='typePayment' hide-details class='my-0 pb-0' row>
            <v-radio :label="$t('full')" value='1'></v-radio>
            <v-radio :label="$t('installment')" value='2'></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
          <span class='primary--text text-decoration-underline'>{{ totalPay | formatPrice }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row justify='center' align='center'>
                <v-col cols='5'>
                  {{ $t('amount_baht') }}
                  <span v-if='typePayment == 2' class='primary--text'>({{ $t('period') }} 1) </span>
                </v-col>
                <v-col cols='7'>
                  <v-text-field
                    v-model='payAmount'
                    outlined
                    hide-details
                    :disabled="typePayment == '1'"
                    reverse
                    dense
                    type='number'
                  >
                    <template v-slot:prepend>
                      <v-icon color='primary'
                              @click='payAmount > 0 ? payAmount-- : (payAmount = 0)'>
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color='primary' @click='payAmount++'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align='center' justify='center' v-if='coupon_amount<=0'>
                <v-col cols='5'>
                  {{ $t('cashback') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='cashbackSelect'
                    :items='cashbackTypeList'
                    dense
                    hide-details
                    outlined
                    item-text='text'
                    item-value='value'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if='isLastDate' align='center' justify='center'>
                <v-col cols='5'>
                  <span>
                    {{ $t('separateDeposit') }}
                  </span>
                </v-col>
                <v-col cols='7' class='py-0 my-0'>
                  <v-switch v-model='statusCheckDeposit' :true-value='1'
                            :false-value='0'></v-switch>
                </v-col>
                <v-col cols='5'>
                  <span> {{ $t('selectDatePatyment') }} <br /> </span>
                  <span v-if="changeDateType == '2'">
                    {{ date_create | sumdate }}
                  </span>
                  <span v-if="changeDateType == '1'">
                    {{ dateNow | sumdate }}
                  </span>
                </v-col>
                <v-col cols='7'>
                  <v-radio-group
                    v-model='changeDateType'
                    dense
                    hide-details
                    class='py-0 my-0'
                    :disabled='statusCheckDeposit == 1'
                  >
                    <v-radio :label="$t('useCurrentDate')" value='1'></v-radio>
                    <v-radio :label="$t('useCreateReceipt')" value='2'></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  <small v-if='statusPoint && +totalPay - +orderPointsave > 0' class='error--text'
                  >{{ $t('pointChage') }} {{ +totalPay - +orderPointsave }}</small
                  >
                  <br />
                  {{ $t('channel_pay') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='channelPaySelect'
                    :items='channelPayList'
                    dense
                    hide-details
                    outlined
                    item-text='shop_bank_name'
                    item-value='shop_bank_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  {{ $t('user_is_list') }} <br />
                  {{ $t('staff_team') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    dense
                    hide-details
                    outlined
                    item-text='teams_name'
                    item-value='teams_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="typePayment == '1'" align='center' justify='center'>
                <v-col cols='5'>
                  <v-checkbox v-model='statusPoint' :label="$t('redeem_points')"></v-checkbox>
                  <!-- :disabled="customerData.customer_point < payAmount" -->
                </v-col>
                <v-col cols='7'>
                  <v-text-field
                    v-model='orderPointsave'
                    outlined
                    hide-details
                    :disabled='!statusPoint'
                    reverse
                    dense
                    type='number'
                  >
                    <template v-slot:label>
                      <span class='error--text'> {{ $t('current_points')
                        }} : {{ customerData.customer_point }} </span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='3'>
                  {{ $t('note') }}
                </v-col>
                <v-col cols='9'>
                  <v-textarea
                    v-model='order_comment'
                    :rows='3'
                    hide-details
                    :placeholder="$t('note')"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='processingDeposit'
            :disabled="
              payAmount < 0 ||
              payAmount > totalPay ||
              processingDeposit ||
              +orderPointsave > +customerData.customer_point ||
              (payAmount >= totalPay && typePayment == '2')
            "
            @click='paymentConfirm'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            :loading='processingDeposit'
            :disabled='processingDeposit'
            @click='isPayment = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditCartDrug v-model='isEditCartDrug' :data-edit='dataEdit' @onUpdate='getCartList' />
    <v-dialog v-model='isPaysolutions' persistent max-width='450'>
      <v-card>
        <v-card-title class='text-subtitle-1'>
          {{ $t('payWithPaySulotion') }}
          <v-spacer></v-spacer>
          <span class='primary--text text-decoration-underline'>{{ totalPay | formatPrice }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>

            <v-col cols='12'>
              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  {{ $t('channel_pay') }}
                </v-col>
                <v-col cols='7'>
                  <div style='background-color: #002cc9;font-size: 18px;'
                       class='rounded-lg white--text text-center py-2'>
                    paysolutions
                  </div>
                </v-col>
              </v-row>
              <v-row align='center' justify='center' v-if='coupon_amount<=0'>
                <v-col cols='5'>
                  {{ $t('cashback') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='cashbackSelect'
                    :items='cashbackTypeList'
                    dense
                    hide-details
                    outlined
                    item-text='text'
                    item-value='value'
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row align='center' justify='center'>
                <v-col cols='5'>
                  {{ $t('user_is_list') }} <br />
                  {{ $t('staff_team') }}
                </v-col>
                <v-col cols='7'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    dense
                    hide-details
                    outlined
                    item-text='teams_name'
                    item-value='teams_id'
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='3'>
                  {{ $t('note') }}
                </v-col>
                <v-col cols='9'>
                  <v-textarea
                    v-model='order_comment'
                    :rows='3'
                    hide-details
                    :placeholder="$t('note')"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='processingDeposit'
            :disabled="
            totalPay<=0 ||
              payAmount < 0 ||
              payAmount > totalPay ||
              processingDeposit ||
              +orderPointsave > +customerData.customer_point ||
              (payAmount >= totalPay && typePayment == '2')
            "
            @click='paymentConfirm'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color='secondary'
            outlined
            :loading='processingDeposit'
            :disabled='processingDeposit'
            @click='isPaysolutions = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CashbackList v-model='isShowCashbackList' :cashback-list='listCoupon'
                  @selectionCoupon='updateCashbackSelection' @cancelCashback='cancelCashback' />
  </div>
</template>

<script>
import {
  mdiPill,
  mdiPlus,
  mdiMinus,
  mdiAutoFix,
  mdiStethoscope,
  mdiCardAccountDetailsStarOutline,
  mdiCalendar,
  mdiPencilOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import useCreateOrder from './useCreateOrder'
import PopupCustomer from '@/components/basicComponents/PopupCustomer.vue'
import DrugList from './DrugList.vue'
import CourseList from './CourseList.vue'
import CheckList from './CheckList.vue'
import PayWithPaySulotion from './PayWithPaySulotion.vue'
import MembetList from './MembetList.vue'
import { formatPrice, sumdate } from '@/plugins/filters'
import EditCartDrug from './EditCartDrug.vue'
import CashbackList from '@/views/receipts/CashbackList'

export default {
  filters: {
    formatPrice,
    sumdate,
  },
  components: {
    CashbackList,
    PayWithPaySulotion,
    PopupCustomer,
    DrugList,
    CourseList,
    CheckList,
    EditCartDrug,
    MembetList,
  },
  setup() {
    return {
      ...useCreateOrder(),
      icons: {
        mdiPill,
        mdiAutoFix,
        mdiStethoscope,
        mdiCardAccountDetailsStarOutline,
        mdiCalendar,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiPlus,
        mdiMinus,
      },
    }
  },
}
</script>

<style lang='scss'>
@import '@core/preset/preset/apps/user.scss';
</style>
