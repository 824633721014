import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const limitDiscountGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'limitdiscount',
      param: id,
    })
    .then(res => (res.data ? res.data : {}))
    .catch(err => {
      console.log('get limit discount err : ', err)

      return {}
    })

  return response
}
const limitDiscountList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'limitdiscount/list',
      body,
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get payment chennel list err : ', err)

      return []
    })

  return response
}

const limitDiscountLogList = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'limitdiscount/log',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.log('get log limit discount list err : ', err)

      return []
    })

  return response
}

const limitDiscountUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'limitdiscount',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('update limit discount err : ', err)

      return {}
    })

  return response
}
export default {
  limitDiscountList, limitDiscountLogList, limitDiscountUpdate, limitDiscountGet,
}
