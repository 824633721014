import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const courseGroupList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'coursegroup/list',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get course group list : ', err)

      return []
    })

  return response
}

const courseGroupAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'coursegroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add course group  : ', err)

      return {}
    })

  return response
}

const courseGroupUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'coursegroup',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update course group  : ', err)

      return {}
    })

  return response
}

const courseGroupStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'coursegroup/status',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update course group status  : ', err)

      return {}
    })

  return response
}
export default {
  courseGroupList, courseGroupAdd, courseGroupUpdate, courseGroupStatusUpdate,
}
