import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const receiptDrugList = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/drug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get drug list in receipt err : ', err)

      return {}
    })

  return response
}

const receiptMemberList = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'order/member/list',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('get member list in receipt err : ', err)

      return []
    })

  return response
}

const receiptCourseList = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/course',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get course list in receipt err : ', err)

      return {}
    })

  return response
}

const receiptCheckList = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/checking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get check list in receipt err : ', err)

      return {}
    })

  return response
}

const receiptCartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/cartlist',
      body,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get receipt cart list err : ', err)

      return {}
    })

  return response
}

const receiptAddCartDrug = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/cartdrug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add drug to receipt cart err : ', err)

      return {}
    })

  return response
}
// Node api
const receiptAddCartMember = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'order/member',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('add member to receipt cart err : ', err)

      return {}
    })

  return response
}

const receiptAddCartCourse = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/cartcourse',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add course to receipt cart err : ', err)

      return {}
    })

  return response
}

const receiptAddCartCheck = async (body, path) => {
  ////await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/cartchecking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add check to receipt cart err : ', err)

      return {}
    })

  return response
}

const receiptRemoveCart = async (customerId, id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'order/cart',
      param: `${customerId}/${id}`,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add check to receipt cart err : ', err)

      return {}
    })

  return response
}

const receiptCartUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'order/cart',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add check to receipt cart err : ', err)

      return {}
    })

  return response
}
// PHP api
const receiptCreate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/v2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('create receipt  err : ', err)

      return {}
    })

  return response
}
// Node api
const receiptPay = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'orderlist/paymentv2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('pay receipt  err : ', err)

      return {}
    })

  return response
}
// Node api
const receiptDeposit = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'orderlist/payv2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('deposit receipt  err : ', err)

      return {}
    })

  return response
}

const receiptDrugOldGet = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/cartdrugold',
      body,
    })
    .then(res => res.data.length)
    .catch(err => {
      console.error('deposit receipt  err : ', err)

      return 0
    })

  return response
}

const receiptInstallmentGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'orderlist/pay',
      param: id,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get installment receipt   err : ', err)

      return {}
    })

  return response
}

const receiptInstallmentAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'orderlist/pay',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add installment receipt err : ', err)

      return {}
    })

  return response
}
// Node api
const receiptStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'orderlist/statusv2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update status receipt err : ', err)

      return {}
    })

  return response
}

const receiptUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'order/detail',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get check list in receipt err : ', err)

      return {}
    })

  return response
}

const getCouponList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'order/coupon',
      body,
    })
    .then(res => res)
    .catch(err => {
      console.error('get coupon list in receipt err : ', err)

      return {}
    })

  return response
}


export default {
  getCouponList,
  receiptDrugList,
  receiptCourseList,
  receiptCheckList,
  receiptCartList,
  receiptAddCartDrug,
  receiptAddCartCourse,
  receiptAddCartCheck,
  receiptRemoveCart,
  receiptCartUpdate,
  receiptCreate,
  receiptPay,
  receiptDeposit,
  receiptMemberList,
  receiptAddCartMember,
  receiptDrugOldGet,
  receiptInstallmentGet,
  receiptInstallmentAdd,
  receiptStatusUpdate,
  receiptUpdate,
}
