<template>
  <v-menu open-on-hover max-width="500" nudge-bottom="35" offset-overflow>
    <template #activator="{ on, attrs }">
      <span class="font-weight-semibold">
        <router-link
          v-if="customerData.customer_id_pri"
          :to="{ name: 'DetailCustomer', params: { id: customerData.customer_id_pri } }"
          class="font-weight-medium text-decoration-none"
        >
          <span class="me-1">({{ customerData.customer_id ? customerData.customer_id.trim() : '' }})</span>
        </router-link>

        <span>
          {{ customerData.customer_fname }}
          {{ customerData.customer_lname }}
        </span>
        <v-btn color="primary" icon class="mx-1" v-bind="attrs" v-on="on">
          <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
        </v-btn>
      </span>
    </template>
    <v-card>
      <v-card-title class="justify-center flex-column">
        <v-avatar
          :color="'primary'"
          :class="'v-avatar-light-bg primary--text'"
          size="120"
          rounded
          class="mb-4 elevation-2"
        >
          <v-img :src="customerData.customer_image"></v-img>
        </v-avatar>
        <span>
          <span class="me-1">{{ customerData.customer_id }}</span>
          <span
            v-if="
              customerData.customer_prefix == '-' ||
              customerData.customer_prefix == 'null' ||
              customerData.customer_prefix == null ||
              customerData.customer_prefix == '' ||
              customerData.customer_prefix == 'ไม่ระบุ'
            "
          >
            -
          </span>
          <span v-else>{{ customerData.customer_prefix }}</span> {{ customerData.customer_fname }}
          {{ customerData.customer_lname }}
          <span v-if="customerData.customer_nname && customerData.customer_nname != 'null'"
            >({{ customerData.customer_nname }})</span
          >
        </span>
        <v-chip color="primary" label :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg primary--text mt-2`">
          {{ customerData.customer_group_name }}
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr v-if="customerData.customer_shop">
                <td class="font-weight-bold">{{ $t('branch') }} :</td>
                <td>
                  {{ customerData.customer_shop }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('id_card') }} :</td>
                <td>
                  {{ customerData.customer_idcard }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('age') }} :</td>
                <td>
                  <span v-if="$i18n.locale == 'th'">
                    {{ customerData.customer_birthdate | sum_birthdate }}
                  </span>
                  <span v-if="$i18n.locale == 'en'">
                    {{ customerData.customer_birthdate | sum_birthdateEn }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('gender') }} :</td>
                <td>
                  <span
                    v-if="
                      customerData.customer_gender == '-' ||
                      customerData.customer_gender == 'null' ||
                      customerData.customer_gender == null ||
                      customerData.customer_gender == '' ||
                      customerData.customer_gender == 'ไม่ระบุ'
                    "
                  >
                    -
                  </span>
                  <span v-else>
                    {{ customerData.customer_gender }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('telephone_number') }} :</td>
                <td>
                  {{ customerData.customer_tel }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('reward_points') }} :</td>
                <td>
                  {{ customerData.customer_point | formatPrice }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('blood_type') }} :</td>
                <td>
                  {{ customerData.customer_blood }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('be_allergic') }} :</td>
                <td>{{ customerData.customer_allergic ? customerData.customer_allergic + ',' : '' }}{{ drugList }}</td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('congenital_disease') }} :</td>
                <td>
                  {{ customerData.customer_disease }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-bold">{{ $t('note') }} :</td>
                <td>
                  {{ customerData.customer_comment }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { sum_birthdate, sum_birthdateEn, formatPrice } from '@/plugins/filters'
import { ref, watch } from '@vue/composition-api'
export default {
  filters: {
    sum_birthdate,
    sum_birthdateEn,
    formatPrice,
  },
  props: {
    customerData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const drugList = ref('')
    const customerDataLocal = ref(JSON.parse(JSON.stringify(props.customerData)))

    const dataDrug = customerDataLocal.value.drug.map(item => {
      return item.drug_name
    })
    drugList.value = String(dataDrug)
    return {
      drugList,
      icons: {
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
