<template>
  <v-chip
    :color="type == 1?'primary':type == 2?'warning':'info'"
    :class="type==1?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:type==2?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg warning--text`:`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
    small
  >
    <v-icon
      small
      class="me-1"
    >
      {{ type == 1 ?icons.mdiTimetable: type == 2 ?icons.mdiClockOutline:icons.mdiPill }}
    </v-icon>
    {{ type == 1 ? $t('limited_time') : type == 2 ? $t('limited_day') :$t('limited_drug') }}
  </v-chip>
</template>

<script>
import { mdiTimetable, mdiClockOutline, mdiPill } from '@mdi/js'

export default {
  props: { type: String },
  setup() {
    return {
      icons: {
        mdiTimetable,
        mdiClockOutline,
        mdiPill,
      },
    }
  },
}
</script>
