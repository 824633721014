<template>
  <div>
    <v-row class='px-2' no-gutters>
      <v-col cols='12' lg='5'>
        <v-autocomplete
          v-model='druggroup_id'
          :items='dataDrugGroupList'
          item-text='druggroup_name'
          item-value='druggroup_id'
          dense
          hide-details
          class='my-2 mx-1'
          :label="$t('MedicimeEquipmentGroup')"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols='12' lg='7'>
        <v-text-field
          v-model='searchtext'
          hide-details
          :placeholder="`${$t('code')}, ${$t('name')} ${$t('drug')}`"
          class='my-2 mx-1'
          outlined
          dense
          :label="$t('searchDrugEquipment')"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers='columns'
      :items='dataDrugList'
      fixed-header
      :options.sync='options'
      :server-items-length='totalDataTableList'
      :footer-props='footer'
      :loading='loading'
      mobile-breakpoint='0'
      height='465'
      dense
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.drug_price`]='{ item }'>
        {{ item.drug_price | formatPrice }}
      </template>
      <template v-slot:[`item.drug_name`]='{ item }'>
        <v-chip
          v-if="druggroup_id === 'set'"
          color='primary'
          :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
          small
        >
          <span>
            {{ $t('set') }}
          </span>
        </v-chip>
        <v-icon v-if="item.drug_category_id == '1'" small color='primary'>
          {{ icons.mdiPill }}
        </v-icon>
        <v-icon v-if="item.drug_category_id == '2'" small color='info'>
          {{ icons.mdiNeedle }}
        </v-icon>
        {{ item.drug_name }}
      </template>
      <template v-slot:[`item.drug_total`]='{ item }'>
        {{ item.drug_total | formatPrice }}
      </template>
      <template v-slot:[`item.actions`]='{ item }'>
        <v-tooltip top color='#212121'>
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              color='primary'
              icon
              v-bind='attrs'
              :disabled='+item.drug_total <= 0 && druggroup_id !== "set"'
              v-on='on'
              @click='selectDrug(item)'
            >
              <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('select') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model='isSelectDrug' max-width='400' persistent>
      <v-card>
        <v-card-title class='text-subtitle-1'>
          <span>
             <v-chip
               v-if="druggroup_id === 'set'"
               color='primary'
               :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`"
               small
             >
          <span>
            {{ $t('setDrug') }}
          </span>
        </v-chip>
            <DrugStatus v-else class='mb-1' :type='objDrugSelect.drug_category_id' />
            ({{ objDrugSelect.drug_id }}) {{ objDrugSelect.drug_name }}
            <span class='primary--text'>
              {{ $t('price') }}
              <span class='error--text mx-2'>{{ objDrugSelect.drug_price }}</span>
              {{ $t('THB') }}
            </span>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert dense text type='error' v-if='isDrugCustomer'>
            <div align='center'>
              {{ $t('customerDrugFiald') }}
            </div>
          </v-alert>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('a_price_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='drugPrice'
                    outlined
                    hide-details
                    reverse
                    :disabled="druggroup_id === 'set'"
                    dense
                    type='number'
                    @input='handleChangeDrugAmount'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='drugPrice > 0 ? drugPrice-- : (drugPrice = 0), handleChangeDrugAmount()'
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color='primary' @click='drugPrice++, handleChangeDrugAmount()'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('amount') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='drugAmount'
                    outlined
                    reverse
                    :disabled="druggroup_id === 'set'"
                    hide-details
                    dense
                    @input='handleChangeDrugAmount'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='drugAmount > 1 ? drugAmount-- : (drugAmount = 1); handleChangeDrugAmount()'
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color='primary' @click='drugAmount++; handleChangeDrugAmount()'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('discount_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='drugDiscount'
                    outlined
                    hide-details
                    reverse
                    :disabled="druggroup_id === 'set'"
                    dense
                    type='number'
                    @input='handleChangeDrugAmount'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='drugDiscount > 0 ? drugDiscount-- : (drugDiscount = 0), handleChangeDrugAmount()'
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color='primary' @click='drugDiscount++, handleChangeDrugAmount()'>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols='12'>
              <v-card-title class='pl-0 pr-2 text-subtitle-1 font-weight-semibold'>
                <span class='me-2'> {{ $t('total_price') }}</span>
                <v-spacer></v-spacer>
                <u class='error--text me-2'> {{ drugPriceTotal | formatPrice }}</u>
                {{ $t('THB') }}
              </v-card-title>
            </v-col>

            <v-col cols='12'>
              <v-row v-if='commissionId' align='center' justify='center'>
                <v-col cols='4' class='font-weight-semibold'>
                  {{ $t('commission') }}
                </v-col>
                <v-col cols='8'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    item-text='teams_name'
                    :placeholder="$t('staff_team')"
                    item-value='teams_id'
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center' v-if='druggroup_id !== `set`'>
                <v-col cols='4' class='font-weight-semibold'>
                  {{ $t('direction') }}
                </v-col>
                <v-col cols='8'>
                  <v-textarea
                    v-model='drugDirection'
                    :rows='3'
                    hide-details
                    :placeholder="$t('direction')"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :loading='addCartLoading'
            :disabled='drugPriceTotal < 0 || drugAmount < 1 || addCartLoading'
            @click='addDrugToCart'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='isSelectDrug = false'>
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCheckCircleOutline, mdiMinus, mdiPlus, mdiNeedle, mdiPill } from '@mdi/js'
import drugGroup from '@/api/DrugManagement/drugGroup'
import createReceipt from '@/api/receipt/createReceipt'
import footerDataTables from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTables-smEN.json'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import store from '@/store'
import { useRouter } from '@/@core/utils'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'

export default {
  components: {
    DrugStatus,
  },
  filters: {
    formatPrice,
  },

  props: {
    commissionId: {
      type: Boolean,
      default: false,
    },
    teamsAndUserList: {
      type: Array,
      default: () => [],
    },
    statusDelete: {
      type: Boolean,
      default: false,
    },
    opdId: {
      type: String,
      default: '',
    },
    customerId: {
      type: String,
      default: '',
    },
    customerDrugList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const dataDrugList = ref([])
    const dataDrugGroupList = ref([])
    const searchtext = ref('')
    const druggroup_id = ref('')
    const drug_id_pri = ref('')
    const options = ref({})
    const columns = ref([
      { text: i18n.t('code'), value: 'drug_id', width: 50 },
      { text: i18n.t('name'), value: 'drug_name', width: 200 },
      {
        text: i18n.t('price'),
        value: 'drug_price',
        width: 80,
        align: 'end',
      },
      {
        text: i18n.t('balance'),
        value: 'drug_total',
        width: 80,
        align: 'end',
      },
      {
        text: i18n.t('select'),
        value: 'actions',
        width: 50,
        align: 'center fixed',
      },
    ])
    const loading = ref(false)
    const totalDataTableList = ref(0)
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const isSelectDrug = ref(false)
    const objDrugSelect = ref({})
    const drugPrice = ref(0)
    const drugDirection = ref('')
    const drugAmount = ref(1)
    const drugDiscount = ref(0)
    const drugPriceTotal = ref(0)
    const addCartLoading = ref(false)
    const processing = ref(false)
    const commissionSelect = ref('')
    const isDrugCustomer = ref(null)
    const router = useRouter()

    const { drugGroupList } = drugGroup
    const { receiptDrugList, receiptAddCartDrug } = createReceipt

    drugGroupList({
      searchtext: '',
      drug_category_id: '',
      druggroup_status_id: '1',
    }).then(res => {
      dataDrugGroupList.value = res.data
      dataDrugGroupList.value.unshift({
        druggroup_id: 'set',
        druggroup_name: i18n.t('setDrug'),
      })
      dataDrugGroupList.value.unshift({
        druggroup_id: '',
        druggroup_name: i18n.t('all_categories'),
      })
    })

    watch(
      () => props.teamsAndUserList,
      value => {
        commissionSelect.value = value.find(item => item.active_id == 1)
          ? value.find(item => item.active_id == 1).teams_id
          : null
      },
    )

    watch(
      () => props.statusDelete,
      () => {
        getDrugList()
      },
    )

    watch(
      () => props.customerId,
      value => {
        if (value) {
          getDrugList()
        }
      },
    )

    const getDrugList = () => {
      loading.value = true
      receiptDrugList(
        {
          searchtext: searchtext.value,
          customer_id_pri: props.customerId,
          opd_id: router.route.value.name === 'creare-order-opd' ? router.route.value.params.id : '',
          druggroup_id: druggroup_id.value,
          active_page: options.value.page,
          per_page: options.value.itemsPerPage === -1 ? '0' : options.value.itemsPerPage,
        },
        druggroup_id.value === 'set' ? 'order/drugset' : 'order/drug',
      ).then(res => {
        const { data, count, per_page } = res
        dataDrugList.value = druggroup_id.value === 'set' ? data.map(item => {
          item.drug_id = item.drugset_code
          item.drug_name = item.drugset_name
          item.drug_price = item.drugset_price
          item.drug_total = item.drugset_price
          item.drug_direction = item.drugset_direction
          return item
        }) : data
        totalDataTableList.value = count
        options.value.page = per_page
        loading.value = false
      })
    }

    const getDrugSetList = () => {

    }

    const selectDrug = value => {
      if (props.customerDrugList.length) {
        const item = props.customerDrugList.find(el => value.drug_id_pri == el.drug_id_pri)
        isDrugCustomer.value = item || ''
      }
      objDrugSelect.value = value
      drugPrice.value = value.drug_price
      drugDirection.value = value.drug_direction
      drugAmount.value = 1
      drugDiscount.value = 0
      handleChangeDrugAmount()
      isSelectDrug.value = true
    }

    const handleChangeDrugAmount = () => {
      setTimeout(() => {
        drugAmount.value = drugAmount.value < 1 ? 1 : drugAmount.value
      }, 100)

      if (!drugDiscount.value) {
        drugDiscount.value = 0
      }
      if (!drugPrice.value) {
        drugPrice.value = 0
      }
      const pricePerUnit = +drugPrice.value * +drugAmount.value
      const discountPerUnit = +pricePerUnit - +drugAmount.value * +drugDiscount.value
      drugPriceTotal.value = discountPerUnit
    }

    const addDrugToCart = () => {
      addCartLoading.value = true
      let body = druggroup_id.value === 'set' ? {
        opd_id: props.opdId,
        customer_id_pri: props.customerId,
        drugset_id: objDrugSelect.value.drugset_id,
        drugset_direction: drugDirection.value,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      } : {
        opd_id: props.opdId,
        customer_id_pri: props.customerId,
        drug_id_pri: objDrugSelect.value.drug_id_pri,
        drug_amount: drugAmount.value,
        drug_price: drugPrice.value,
        drug_discount: drugDiscount.value,
        drug_total: drugPriceTotal.value,
        drug_direction: drugDirection.value,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      }
      receiptAddCartDrug(
        body,
        druggroup_id.value === 'set' ? 'order/cartdrugset' : 'order/cartdrug',
      ).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getDrugList()
        emit('onAdd')
        isSelectDrug.value = false
        addCartLoading.value = false
      })
    }

    watch([searchtext, druggroup_id, options], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
        options.value.page = 1
      }
      getDrugList()
    })

    return {
      commissionSelect,
      dataDrugList,
      dataDrugGroupList,
      searchtext,
      druggroup_id,
      totalDataTableList,
      drug_id_pri,
      options,
      footer,
      columns,
      loading,
      isSelectDrug,
      objDrugSelect,
      drugPrice,
      drugDirection,
      drugAmount,
      drugDiscount,
      drugPriceTotal,
      selectDrug,
      addCartLoading,
      getDrugList,
      addDrugToCart,
      handleChangeDrugAmount,
      isDrugCustomer,
      processing,
      icons: {
        mdiCheckCircleOutline,
        mdiMinus,
        mdiPlus,
        mdiNeedle,
        mdiPill,
      },
    }
  },
}
</script>
