import refreshToken from '@/plugins/refresh-token'

import { api } from '../main'

import { api as api2 } from '../mainNode'

const receiptList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'orderlist/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get receipt list err : ', err)

      return {}
    })

  return response
}

const receiptRefundGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'orderlist/refund',
      param: id,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('refund receipt get err : ', err)

      return {}
    })

  return response
}

const receiptRefund = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'orderlist/refund',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('refund receipt err : ', err)

      return {}
    })

  return response
}

const paymentWithPaySolution = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'order/paysolutions',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('refund receipt err : ', err)

      return {}
    })

  return response
}

const checkPayment = async param => {
  const response = await api2
    .get({
      path: 'orderStatus',
      param: param,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('refund receipt err : ', err)

      return {}
    })

  return response
}

export default { receiptList, receiptRefundGet, receiptRefund, paymentWithPaySolution, checkPayment }
