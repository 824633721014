<template>
  <v-dialog v-model='isShowCashback' persistent max-width='600'>
    <v-card>
      <v-card-title>
        {{ $t('cashbackList') }}
      </v-card-title>
      <v-simple-table dense>
        <thead>
        <tr>
          <th>
            {{ $t('a_number') }}
          </th>
          <th>
            {{ $t('name') }}
          </th>
          <th style='min-width: 110px;'>
            {{ $t('dateBalance') }}
          </th>
          <th class='text-right'>
            {{ $t('amount') }}
          </th>
          <th class='text-center'>
            {{ $t('option') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='(item,index) in cashbackList' :key='index'>
          <td>{{ item.order_id || '-' }}</td>
          <td>{{ item.coupon_name }}</td>
          <td style='min-width: 110px;'>{{ item.coupon_expiredate | sumdate }}</td>
          <td class='text-right'>{{ item.coupon_amount }}</td>
          <td class='text-center'>
            <v-btn color='primary' icon @click='onSelection(item)'>
              <v-icon>
                {{ mdiCheckCircleOutline }}
              </v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-divider class='mb-2'></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color='error' @click='$emit(`cancelCashback`)' v-if='statusC != `edit`'>
          {{ $t('cashbackCancel') }}
        </v-btn>
        <v-btn color='secondary' outlined @click='$emit(`update:isShowCashback`,false)'>
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { sumdate } from '@/plugins/filters'
import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  filters: {
    sumdate,
  },
  name: 'CashbackList',
  model: {
    prop: 'isShowCashback',
    event: 'update:isShowCashback',
  },
  props: {
    isShowCashback: {
      type: Boolean,
      default: false,
    },
    cashbackList: {
      type: Array,
      default: () => [],
    },
    statusC: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const onSelection = (item) => {
      emit('selectionCoupon', item)
      emit('update:isShowCashback', false)
    }
    return ({
      onSelection,
      mdiCheckCircleOutline,
    })
  },
}


</script>

<style scoped>

</style>
