import refreshToken from '@/plugins/refresh-token'
import { api } from '../mainNode'

// Node api All Functions
const memberTypeList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'membertype/list',
      body,
    })
    .then(res => (res.data))
    .catch(err => {
      console.error('get member err : ', err)

      return []
    })

  return response
}
const memberTypeAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'membertype',
      body,
    })
    .then(res => (res))
    .catch(err => {
      console.error('add member err : ', err)

      return {}
    })

  return response
}

const memberTypeUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'membertyp',
      body,
    })
    .then(res => (res))
    .catch(err => {
      console.error('update member err : ', err)

      return {}
    })

  return response
}

const memberTypeStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'membertype/status',
      body,
    })
    .then(res => (res))
    .catch(err => {
      console.error('update status member err : ', err)

      return {}
    })

  return response
}

export default {
  memberTypeList, memberTypeAdd, memberTypeUpdate, memberTypeStatusUpdate,
}
