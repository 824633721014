import vatData from '@/@fake-db/data/vatData.json'
import customer from '@/api/customer/customer'
import profile from '@/api/profile'
import receipt from '@/api/receipt/createReceipt'
import shopBank from '@/api/shopbank'
import document from '@/api/systemSetting/document'
import limitDiscount from '@/api/systemSetting/limitDiscount'
import teamList from '@/api/teamList'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'


export default function useCreateOrder() {
  window.document.title = `${i18n.t('CreateReceipt')} | APSTH Platform Clinic 3.9`
  const moment = require('moment')
  const tab = ref(null)
  const customerData = ref({})
  const orderId = ref('')
  const leftBlockLoading = ref(true)
  const date_create = ref(moment(new Date()).format('YYYY-MM-DD'))
  const isShowDate = ref(false)
  const cartLoading = ref(false)
  const sumLoading = ref(false)
  const taxTypeList = ref(vatData.data)
  const changeDateType = ref('2')
  const discountStatus = ref(0)
  const statusCheckDeposit = ref(1)
  const isConfirmPatment = ref(false)

  const cashbackTypeList = ref([
    {
      'value': '0',
      'text': i18n.t('unknown'),
    },
    {
      'value': '1',
      'text': i18n.t('pointFormat'),
    },
    {
      'value': '2',
      'text': i18n.t('couponFormat'),
    },
  ])
  const cashbackSelect = ref('0')
  // data Receipt details
  const cartList = ref([])
  const totalPrice = ref(0)
  const discount = ref(0)
  const discountType = ref('0')
  const totalAfterDiscount = ref(0)
  const taxType = ref(0)
  const vat = ref(0)
  const totalValue = ref(0)
  const totalPay = ref(0)
  const limitDisCount = ref(0)
  const calculateLimitDisCount = ref(0)
  const commissionStatus = ref(false)
  const teamsAndUserList = ref([])
  const removeLoading = ref(false)
  const isEditCartDrug = ref(false)
  const statusDelete = ref(false)
  const processingDeposit = ref(false)
  const customerDrugList = ref([])
  const dataEdit = ref({})
  const paySolutionsStatus = ref(localStorage.getItem('paySolutions'))
  const dateNow = ref(moment(new Date()).format('YYYY-MM-DD'))
  const discountTypeList = ref([])
  const isShowCashbackList = ref(false)

  // cart data for
  const options = ref({})

  // { text: i18n.t('code'), value: 'orderdetail_temp_id', width: 100 },
  const columns = ref([
    { text: '#', value: 'id', width: 30 },

    { text: `${i18n.t('list')}/${i18n.t('service')}`, value: 'orderdetail_temp_name', width: 200 },
    {
      text: i18n.t('amount'),
      value: 'orderdetail_temp_amount',
      align: 'end',
      width: 80,
    },
    {
      text: `${i18n.t('price')}/${i18n.t('unit')}`,
      value: 'checking_id',
      align: 'end',
      width: 100,
    },
    {
      text: `${i18n.t('discount')}/${i18n.t('unit')}`,
      value: 'orderdetail_temp_discount',
      align: 'end',
      width: 110,
    },
    {
      text: i18n.t('total_price'),
      value: 'orderdetail_temp_total',
      align: 'end',
      width: 100,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'end fixed',
      width: 95,
    },
  ])

  // for payment
  const channelPayList = ref([])
  const order_comment = ref('')
  const depositAmount = ref(0)
  const commissionSelect = ref('')
  const channelPaySelect = ref('')
  const payAmount = ref(0)
  const statusPoint = ref(false)
  const typePayment = ref('1')
  const orderPointsave = ref(0)
  const isPayment = ref(false)
  const isDeposit = ref(false)
  const processing = ref(false)
  const loadingGetOld = ref(false)
  const isLastDate = ref(false)
  const statusCreateOrder = ref(false)
  const isPaysolutions = ref(false)
  const newId = ref('')
  const currentCurrency = ref('')

  const listCoupon = ref([])
  const couponSelect = ref('')
  const coupon_amount = ref('0.00')

  const { customerById } = customer
  const { documentSetting } = document
  const { shopbankList } = shopBank
  const {
    receiptCartList,
    receiptRemoveCart,
    receiptCreate,
    receiptPay,
    receiptDeposit,
    getCouponList,
    receiptDrugOldGet,
  } = receipt
  const { teamListGet } = teamList

  const { limitDiscountGet } = limitDiscount

  // ดึงข้อมูลผู้ใช้งาน
  profile().then(res => {
    getLimitDiscount(res.role_id)
  })

  const cancelCashback = () => {
    coupon_amount.value = '0.00'
    couponSelect.value = {}
    isShowCashbackList.value = false
    calculateDiscount()
  }

  // ดึงข้อมูลจำกีัดสิทธิ์ส่วนลด
  const getLimitDiscount = role => {
    limitDiscountGet(role).then(res => {
      limitDisCount.value = +res.role_shop_discount
      calLimitDisCount()
    })
  }

  const updateCashbackSelection = (item) => {
    coupon_amount.value = item.coupon_amount
    couponSelect.value = item
    calculateDiscount()
  }

  const getCoupon = () => {
    getCouponList({
      customer_id_pri: router.currentRoute.params.id,
      coupon_status_id: 1,
      active_page: 1,
      per_page: 0,
      lang: i18n.locale,
    }).then(({ data }) => {
      listCoupon.value = data
    })
  }

  // คำนวณส่วนลดสูงสุดต่อประเภทส่วนลดที่เลือก
  const calLimitDisCount = () => {
    if (discountType.value == 1) {
      calculateLimitDisCount.value = +limitDisCount.value
    } else {
      calculateLimitDisCount.value = (+totalPrice.value * +limitDisCount.value) / 100
    }
  }

  const checkDiscount = () => {
    if (discountType.value == 1) {
      totalAfterDiscount.value = (totalPrice.value - (totalPrice.value * discount.value) / 100) - +coupon_amount.value
    } else {
      totalAfterDiscount.value = (totalPrice.value - discount.value) - +coupon_amount.value
    }
    calculateTax()
  }

  const calculateDiscount = () => {
    if (+discount.value > +calculateLimitDisCount.value) {
      discount.value = +calculateLimitDisCount.value
    }
    checkDiscount()
  }

  const discountTypeChenage = () => {
    if (discountType.value == 1) {
      discount.value = (100 / +totalPrice.value) * +discount.value
      totalAfterDiscount.value = (totalPrice.value - (totalPrice.value * discount.value) / 100) - +coupon_amount.value
    } else {
      discount.value = (+totalPrice.value * +discount.value) / 100
      totalAfterDiscount.value = (totalPrice.value - discount.value) - +coupon_amount.value
    }
    calLimitDisCount()
    calculateTax()
  }

  const calculateTax = () => {
    if (taxType.value == 1) {
      vat.value = 0
      totalValue.value = totalAfterDiscount.value
      totalPay.value = totalAfterDiscount.value
    } else if (taxType.value == 2) {
      const sum = (totalAfterDiscount.value * 7) / 107
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value + vat.value
    } else if (taxType.value == 3) {
      const sum = (totalAfterDiscount.value * 7) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value
    } else if (taxType.value == 4) {
      const sum = (totalAfterDiscount.value * 3) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value + vat.value
    } else if (taxType.value == 5) {
      const sum = (totalAfterDiscount.value * 3) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value``
    } else if (taxType.value == 6) {
      const sum = (totalAfterDiscount.value * 10) / 110
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value
    } else if (taxType.value == 7) {
      const sum = (totalAfterDiscount.value * 10) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value
    }
  }

  onMounted(() => {
    getCoupon()
    getCartList()
    getTeamList()
    setTimeout(() => {
      currentCurrency.value = localStorage.getItem('currency')
      if (currentCurrency.value == 2) {
        discountTypeList.value = [
          {
            label: '%',
            value: 1,
          },
          {
            label: i18n.t('USD'),
            value: 2,
          },
        ]
      } else {
        discountTypeList.value = [
          {
            label: '%',
            value: 1,
          },
          {
            label: i18n.t('bath'),
            value: 2,
          },
        ]
      }
    }, 1000)
  })

  const getDocsSettings = () => {
    documentSetting().then(res => {
      orderId.value = res.receipt
      commissionStatus.value = res.commission_id == '1'
      cashbackSelect.value = res.cashback_id
      leftBlockLoading.value = false
    })
  }

  const getCartList = () => {
    cartLoading.value = true
    receiptCartList({
      customer_id_pri: router.currentRoute.params.id,
      lang: i18n.locale,
    }).then(res => {
      cartList.value = res.cart || []
      totalPrice.value = +res.order_price
      discount.value = +res.order_save_amount
      discountType.value = +res.order_save_id
      totalAfterDiscount.value = +res.order_befor_tax
      taxType.value = +res.order_tax_id
      vat.value = +res.order_tax
      totalValue.value = +res.order_total
      totalPay.value = +res.order_totalpay
      discountStatus.value = res.discount_status
      options.value.page = 1
      options.value.itemsPerPage = -1
      cartLoading.value = false
      calLimitDisCount()
      calculateDiscount()
    })
  }

  const removeCart = id => {
    removeLoading.value = true
    receiptRemoveCart(router.currentRoute.params.id, id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getCartList()
      statusDelete.value = !statusDelete.value
      removeLoading.value = false
    })
  }

  const getTeamList = () => {
    teamListGet().then(res => {
      teamsAndUserList.value = res
      commissionSelect.value = res.find(item => item.active_id == 1).teams_id
    })
  }

  customerById(router.currentRoute.params.id).then(res => {
    customerData.value = res
    customerDrugList.value = res.drug
    getDocsSettings()
  })

  const saveDraft = () => {
    processing.value = true
    receiptCreate({
      customer_id_pri: router.currentRoute.params.id,
      order_comment: '',
      order_price: totalPrice.value,
      order_save_id: discountType.value,
      order_save_amount: discount.value || 0,
      order_save: discountType.value == 1 ? (+totalPrice.value * +discount.value) / 100 : discount.value,
      order_befor_tax: totalAfterDiscount.value,
      order_tax_id: taxType.value,
      order_tax: vat.value,
      order_total: totalValue.value,
      order_totalpay: totalPay.value,
      order_deposit_id: 0,
      coupon_id: couponSelect.value.coupon_id,
      order_coupon: couponSelect.value.coupon_amount,
      order_draft_id: 1,
      order_deposit_amount: 0,
      order_deposit_bank_id: '',
      order_deposit_teams_id: '',
      order_create: date_create.value,

      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'order-detail',
          params: { id: res.data },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processing.value = false
      }
    })
  }

  const createOrder = () => {
    processing.value = true
    receiptCreate({
      customer_id_pri: router.currentRoute.params.id,
      order_comment: '',
      order_price: totalPrice.value,
      order_save_id: discountType.value,
      order_save_amount: discount.value || 0,
      order_save: discountType.value == 1 ? (+totalPrice.value * +discount.value) / 100 : discount.value,
      order_befor_tax: totalAfterDiscount.value,
      order_tax_id: taxType.value,
      order_tax: vat.value,
      order_total: totalValue.value,
      coupon_id: couponSelect.value.coupon_id,
      order_coupon: couponSelect.value.coupon_amount,
      order_totalpay: totalPay.value,
      order_deposit_id: 0,
      order_draft_id: 1,
      order_deposit_amount: 0,
      order_deposit_bank_id: '',
      order_deposit_teams_id: '',
      order_create: date_create.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'order-detail',
          params: { id: res.data },
          query: { pay: 'paysolutions' },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processing.value = false
      }
    })
  }

  const depositConfirm = () => {
    processingDeposit.value = true
    receiptCreate({
      customer_id_pri: router.currentRoute.params.id,
      order_comment: order_comment.value,
      order_price: totalPrice.value,
      order_save_id: discountType.value,
      order_save_amount: discount.value || 0,
      order_save: discountType.value == 1 ? (+totalPrice.value * +discount.value) / 100 : discount.value,
      order_befor_tax: totalAfterDiscount.value,
      order_tax_id: taxType.value,
      order_tax: vat.value,
      coupon_id: couponSelect.value.coupon_id,
      order_coupon: couponSelect.value.coupon_amount,
      order_total: totalValue.value,
      order_totalpay: totalPay.value,
      order_deposit_id: 1,
      order_deposit_amount: depositAmount.value,
      order_deposit_bank_id: channelPaySelect.value,
      order_deposit_teams_id: commissionSelect.value,
      order_create: date_create.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'Receipt-list',
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processingDeposit.value = false
      }
    })
  }

  const paymentConfirm = () => {
    processingDeposit.value = true
    receiptCreate({
      customer_id_pri: router.currentRoute.params.id,
      order_comment: order_comment.value,
      order_price: totalPrice.value,
      order_save_id: discountType.value,
      order_save_amount: discount.value || 0,
      order_save: discountType.value == 1 ? (+totalPrice.value * +discount.value) / 100 : discount.value,
      order_befor_tax: totalAfterDiscount.value,
      order_tax_id: taxType.value,
      coupon_id: couponSelect.value.coupon_id,
      order_coupon: couponSelect.value.coupon_amount,
      order_tax: vat.value,
      order_total: totalValue.value,
      order_totalpay: totalPay.value,
      order_deposit_id: 0,
      order_deposit_bank_id: channelPaySelect.value,
      order_deposit_teams_id: commissionSelect.value,
      status_check_deposit: String(statusCheckDeposit.value),
      order_create: date_create.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        if (isPaysolutions.value) {
          paysolutions(res.data)
        } else {
          pay(res.data)
        }
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processingDeposit.value = false
      }
    })
  }

  const paysolutions = id => {
    receiptPay({
      order_id_pri: id,
      order_pay_id: 3,
      order_comment: order_comment.value,
      order_point_id: null,
      order_pointsave: null,
      check_date_id: 2,
      shop_bank_id: null,
      teams_id: commissionSelect.value,
      orderpay_datetime: date_create.value,
      paysolutions_status_id: '1',
      status_check_deposit: null,
      cashback_type_id: cashbackSelect.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'order-detail',
          params: { id },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processingDeposit.value = false
      }
    })
  }

  const pay = id => {
    receiptPay({
      order_id_pri: id,
      order_comment: order_comment.value,
      order_pay_id: typePayment.value == '1' ? 2 : 3,
      order_point_id: !statusPoint.value ? 1 : 2,
      order_pointsave: orderPointsave.value ? orderPointsave.value : '',
      shop_bank_id: channelPaySelect.value,
      teams_id: commissionSelect.value,
      orderpay_datetime: date_create.value,
      check_date_id: changeDateType.value,
      paysolutions_status_id: '0',
      status_check_deposit: String(statusCheckDeposit.value),
      cashback_type_id: cashbackSelect.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        if (typePayment.value == '2') {
          depositPayment(id)
        } else {
          store.commit('app/ALERT', {
            message: 'add_data_successfully',
            color: 'success',
          })
          store.commit('app/setStatusUpdateHeader')
          router.push({
            name: 'order-detail',
            params: { id },
          })
        }
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processingDeposit.value = false
      }
    })
  }

  const depositPayment = id => {
    receiptDeposit({
      order_id_pri: id,
      orderpay_current: payAmount.value,
      shop_bank_id: channelPaySelect.value,
      orderpay_comment: order_comment.value,
      orderpay_datetime: changeDateType.value == '1' ? dateNow.value : date_create.value,
      check_date_id: changeDateType.value,
      status_check_deposit: String(statusCheckDeposit.value),
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'order-detail',
          params: { id },
        })
      } else {
        store.commit('app/ALERT', {
          message: 'error',
          color: 'success',
        })
        processingDeposit.value = false
      }
    })
  }

  const payment = () => {
    getPaymentChanel()
    getTeamList()
    order_comment.value = ''
    payAmount.value = totalPay.value
    isPayment.value = true
    statusPoint.value = false
    typePayment.value = '1'
  }

  const deposit = () => {
    getPaymentChanel()
    getTeamList()
    isDeposit.value = true
  }

  const getPaymentChanel = () => {
    shopbankList().then(res => {
      channelPayList.value = res
      channelPaySelect.value = res[0].shop_bank_id
    })
  }

  const getDrugOld = () => {
    loadingGetOld.value = true
    receiptDrugOldGet({
      customer_id_pri: router.currentRoute.params.id,
    }).then(res => {
      if (res >= 1) {
        store.commit('app/ALERT', {
          message: 'completed_successfully',
          color: 'success',
        })
        getCartList()
      } else {
        store.commit('app/ALERT', {
          message: 'not_drug_equipment_in_the_lasted_receipt',
          color: 'error',
        })
      }
      loadingGetOld.value = false
    })
  }

  watch(statusPoint, value => {
    if (value) {
      if (+customerData.value.customer_point <= +payAmount.value) {
        orderPointsave.value = +customerData.value.customer_point
      } else {
        orderPointsave.value = +payAmount.value
      }
    } else {
      orderPointsave.value = 0
    }
  })

  watch(date_create, value => {
    isLastDate.value = moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(value)
  })

  watch([discount], value => {
    if (value == '') {
      discount.value = 0
    }
  })

  watch(typePayment, () => {
    payAmount.value = totalPay.value
  })

  return {
    updateCashbackSelection,
    isShowCashbackList,
    listCoupon,
    couponSelect,
    statusCreateOrder,
    cashbackSelect,
    cashbackTypeList,
    createOrder,
    tab,
    customerData,
    orderId,
    leftBlockLoading,
    date_create,
    isShowDate,
    cartLoading,
    sumLoading,
    cartList,
    totalPrice,
    discount,
    discountType,
    coupon_amount,
    totalAfterDiscount,
    taxType,
    vat,
    totalValue,
    totalPay,
    options,
    columns,
    limitDisCount,
    calculateLimitDisCount,
    taxTypeList,
    isPaysolutions,
    discountTypeList,
    paySolutionsStatus,
    commissionStatus,
    teamsAndUserList,
    removeLoading,
    isEditCartDrug,
    dataEdit,
    statusDelete,
    processingDeposit,
    processing,
    isPayment,
    isDeposit,
    customerDrugList,
    // payment
    channelPayList,
    order_comment,
    typePayment,
    channelPaySelect,
    commissionSelect,
    payAmount,
    statusPoint,
    orderPointsave,
    depositAmount,
    loadingGetOld,
    changeDateType,
    isLastDate,
    dateNow,
    discountStatus,
    currentCurrency,
    calLimitDisCount,
    calculateDiscount,
    discountTypeChenage,
    calculateTax,
    removeCart,
    getCartList,
    saveDraft,
    deposit,
    payment,
    paymentConfirm,
    cancelCashback,
    statusCheckDeposit,
    depositConfirm,
    getDrugOld,
    isConfirmPatment,
    newId,
  }
}
