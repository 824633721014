import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const customerList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'customer/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get customer list err : ', err)

      return []
    })

  return response
}

const customerById = async id => {
  ////await refreshToken()
  const response = await api2
    .get({
      path: 'customer',
      param: id,
    })
    .then(res => (res.data.length ? res.data[0] : {}))
    .catch(err => {
      console.log('get profile err : ', err)

      return {}
    })

  return response
}
const customerById2 = async id => {
  ////await refreshToken()
  const response = await api2
    .get({
      path: 'customer',
      param: id,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get profile err : ', err)

      return {}
    })

  return response
}
const customerAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add customer err : ', err)

      return {}
    })

  return response
}
const customerUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer/edit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update customer err : ', err)

      return {}
    })

  return response
}
const customerStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'customer/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('Suspend or unsuspend customer err : ', err)

      return {}
    })

  return response
}
const csvFileImport = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer/import',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('import file csv customer err : ', err)

      return {}
    })

  return response
}

const customerDrugList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customer/druglist',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get customer drug list : ', err)

      return {}
    })

  return response
}
export default {
  customerList,
  customerById,
  customerAdd,
  customerUpdate,
  customerStatusUpdate,
  csvFileImport,
  customerDrugList,
  customerById2,
}
