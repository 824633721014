import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const shopbankList = async () => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'ref/shopbank',
    })
    .then(res => (res.data ? res.data : []))
    .catch(err => {
      console.error('get shop bank list error : ', err)

      return []
    })

  return response
}
export default { shopbankList }
