<template>
  <v-dialog
    v-model="isEditCartDrug"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title>
        <span>
          ({{ objDrugSelect.orderdetail_temp_id }})
          {{ objDrugSelect.orderdetail_temp_name }}
          {{ $t("price") }}
          <span class="error--text">{{ objDrugSelect.orderdetail_temp_price }}</span>
          {{ $t("THB") }}
        </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-col cols="6">
                <span class="font-weight-semibold">
                  {{ $t("a_price_unit") }}
                </span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="drugPrice"
                  outlined
                  hide-details
                  reverse
                  dense
                  type="number"
                  @input="handleChangeDrugAmount"
                >
                  <template v-slot:prepend>
                    <v-icon
                      color="primary"
                      @click="
                        drugPrice > 0 ? drugPrice-- : (drugPrice = 0),
                        handleChangeDrugAmount()
                      "
                    >
                      {{ icons.mdiMinus }}
                    </v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon
                      color="primary"
                      @click="drugPrice++, handleChangeDrugAmount()"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-col cols="6">
                <span class="font-weight-semibold">
                  {{ $t("amount") }}
                </span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="drugAmount"
                  outlined
                  reverse
                  hide-details
                  dense
                  type="number"
                  @input="handleChangeDrugAmount"
                >
                  <template v-slot:prepend>
                    <v-icon
                      color="primary"
                      @click="
                        drugAmount > 1 ? drugAmount-- : (drugAmount = 1),
                        handleChangeDrugAmount()
                      "
                    >
                      {{ icons.mdiMinus }}
                    </v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon
                      color="primary"
                      @click="drugAmount++, handleChangeDrugAmount()"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-col cols="6">
                <span class="font-weight-semibold">
                  {{ $t("discount_unit") }}
                </span>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="drugDiscount"
                  outlined
                  hide-details
                  reverse
                  dense
                  type="number"
                  @input="handleChangeDrugAmount"
                >
                  <template v-slot:prepend>
                    <v-icon
                      color="primary"
                      @click="
                        drugDiscount > 0 ? drugDiscount-- : (drugDiscount = 0),
                        handleChangeDrugAmount()
                      "
                    >
                      {{ icons.mdiMinus }}
                    </v-icon>
                  </template>
                  <template v-slot:append-outer>
                    <v-icon
                      color="primary"
                      @click="drugDiscount++, handleChangeDrugAmount()"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-card-title class="pl-0 pr-2 text-subtitle-1 font-weight-semibold">
              <span class="me-2">  {{ $t("total_price") }}</span> <v-spacer></v-spacer>   <u class="error--text me-2"> {{ drugPriceTotal | formatPrice }}</u>
              {{ $t("THB") }}
            </v-card-title>
          </v-col>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-col
                cols="4"
                class="font-weight-semibold"
              >
                {{ $t("direction") }}
              </v-col>
              <v-col cols="8">
                <v-textarea
                  v-model="drugDirection"
                  :rows="3"
                  hide-details
                  :placeholder="$t('direction')"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="editCartLoading"
          :disabled="drugPriceTotal < 0 || drugAmount < 1 ||editCartLoading"
          @click="updateCartDrug"
        >
          {{ $t("confirm") }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="$emit('update:is-edit-cart-drug', false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiMinus } from '@mdi/js'
import { formatPrice } from '@/plugins/filters'
import createReceipt from '@/api/receipt/createReceipt'
import store from '@/store'

export default {
  filters: {
    formatPrice,
  },
  model: {
    prop: 'isEditCartDrug',
    event: 'update:is-edit-cart-drug',
  },
  props: {
    isEditCartDrug: { type: Boolean, default: false },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const objDrugSelect = ref({})
    const drugPrice = ref(0)
    const drugDirection = ref('')
    const drugAmount = ref(1)
    const drugDiscount = ref(0)
    const drugPriceTotal = ref(0)
    const editCartLoading = ref(false)

    const { receiptCartUpdate } = createReceipt

    const selectDrug = item => {
      objDrugSelect.value = item
      drugPrice.value = +item.orderdetail_temp_price
      drugAmount.value = +item.orderdetail_temp_amount
      drugDiscount.value = +item.orderdetail_temp_discount
      drugDirection.value = item.orderdetail_temp_direction
      handleChangeDrugAmount()
    }

    const updateCartDrug = () => {
      editCartLoading.value = true
      receiptCartUpdate({
        orderdetail_temp_id_pri: objDrugSelect.value.orderdetail_temp_id_pri,
        orderdetail_temp_amount: drugAmount.value,
        orderdetail_temp_price: drugPrice.value,
        orderdetail_temp_discount: drugDiscount.value,
        orderdetail_temp_total: drugPriceTotal.value,
        orderdetail_temp_direction: drugDirection.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
        emit('update:is-edit-cart-drug', false)
        editCartLoading.value = false
      })
    }

    watch(() => props.isEditCartDrug, value => {
      if (value) {
        selectDrug(JSON.parse(JSON.stringify(props.dataEdit)))
      }
    })

    const handleChangeDrugAmount = () => {
      if (!drugDiscount.value) {
        drugDiscount.value = 0
      }
      if (!drugPrice.value) {
        drugPrice.value = 0
      }

      const pricePerUnit = +drugPrice.value * +drugAmount.value
      const discountPerUnit = +pricePerUnit - +drugAmount.value * +drugDiscount.value
      drugPriceTotal.value = discountPerUnit
    }

    return {
      objDrugSelect,
      drugPrice,
      drugDirection,
      drugAmount,
      drugDiscount,
      drugPriceTotal,
      editCartLoading,
      selectDrug,
      handleChangeDrugAmount,
      updateCartDrug,
      icons: {
        mdiPlus, mdiMinus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
