<template>
  <div>
    <v-dialog max-width='500' v-model='isConfirmPatment' persistent>
      <v-card>
        <v-card-title>
          <span>
            {{ $t('confirmPayWithPaySulotion') }}
          </span>
          <v-spacer></v-spacer>
          <span class='primary--text text-decoration-underline'>
            {{ orderDetailLocal.order_totalpay | formatPrice }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formPayment' @submit.prevent='payment'>
          <v-card-text v-if='!statusCallPaySolutions'>
            <v-text-field
              :label="$t('customer_code')"
              dense
              :placeholder="$t('customer_code')"
              v-model='customerDataLocal.customer_id'
              disabled
              outlined
              class='me-2 mt-2'
            >
            </v-text-field>
            <v-text-field
              :label="$t('customer_code')"
              dense
              v-model='fullname'
              :placeholder="$t('customer_code')"
              disabled
              outlined
              class='me-2'
            >
            </v-text-field>
            <div class='d-flex'>
              <v-text-field
                :label="$t('tel')"
                dense
                :placeholder="$t('tel')"
                v-model='customerDataLocal.customer_tel'
                :disabled='editTel'
                outlined
                :rules='[required, telValidator]'
                class='me-2'
              >
              </v-text-field>

              <v-btn color='primary' icon>
                <v-icon @click='editTel = false'>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </div>
            <div class='d-flex'>
              <v-text-field
                :label="$t('email')"
                dense
                :placeholder="$t('email')"
                v-model='email'
                :disabled='editEmail'
                outlined
                :rules='[required, emailValidator]'
                class='me-2'
              >
              </v-text-field>

              <v-btn color='primary' icon>
                <v-icon @click='editEmail = false'>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </div>
            <!-- <v-select
              v-model='commissionSelect'
              :items='teamsAndUserList'
              dense
              :label="$t('commission')"
              :placeholder="$t('commission')"
              hide-details='auto'
              outlined
              item-text='teams_name'
              item-value='teams_id'
            >
            </v-select> -->
          </v-card-text>
          <v-card-text v-else>
            <div class='mt-2'>
              <div class='d-flex px-10'>
                <v-avatar size='100' tile color='primary' class='elevation-10 rounded-lg me-3'>
                  <v-img :src='customer_image'></v-img>
                </v-avatar>
                <div>
                  <h3>({{ dataPaySolution.customer_id }}) {{ dataPaySolution.customer_fullname
                    }}</h3>
                  <h3>{{ $t('tel') }} : {{ dataPaySolution.customer_tel }}</h3>
                </div>
              </div>
              <!-- <h3>{{ $t('emil') }} :{{ dataPaySolution.customer_email || '-' }}</h3> -->
              <v-divider class='my-2'></v-divider>
              <div align='center'>
                <h4 class='text-decoration-underline'>QR CODE</h4>
                <VueQrcode class='rounded-lg elevation-4' :value='qrcode'
                           :options='{ width: 200 }'></VueQrcode>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='#002cc9'
              class='white--text'
              type='submit'
              style='width: 140px'
              :loading='loading'
            >
              {{ $t('confirm') }}
              <template v-slot:loader>
                <span>{{ $t('will_be_send_sms_again_in') }}{{ timeer }}</span>
              </template>
            </v-btn>
            <v-btn style='width: 140px' outlined color='error'
                   @click="$emit('update:isConfirmPatment', false)">
              {{ $t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPencilOutline } from '@mdi/js'
import { formatPrice } from '@/plugins/filters'
import { required, telValidator, emailValidator } from '@/@core/utils/validation'
import receipt from '@/api/receipt/receipt'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import store from '@/store'
import router from '@/router'
import teamList from '@/api/teamList'

export default {
  model: {
    prop: 'isConfirmPatment',
    event: 'update:isConfirmPatment',
  },
  components: {
    VueQrcode,
  },
  props: {
    isConfirmPatment: {
      type: Boolean,
      default: false,
    },
    customerData: {
      type: Object,
      default: () => ({}),
    },
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
    statusCreateOrder: {
      type: Boolean,
      default: false,
    },
    newId: {
      type: String,
      default: '',
    },
  },
  filters: {
    formatPrice,
  },
  setup(props, { emit }) {
    const customerDataLocal = ref({})
    const editTel = ref(true)
    const editEmail = ref(true)
    const fullname = ref('')
    const orderDetailLocal = ref({})
    const formPayment = ref(null)
    const loading = ref(false)
    const statusCallPaySolutions = ref(false)
    const dataPaySolution = ref({})
    const qrcode = ref('')
    const timeer = ref(120)
    const teamsAndUserList = ref([])
    const commissionSelect = ref('')
    const customer_image = ref('')
    const email = ref('')
    const count = ref(0)

    const { teamListGet } = teamList

    const getTeamList = () => {
      teamListGet().then(res => {
        teamsAndUserList.value = res
        commissionSelect.value = res.find(item => item.active_id == 1).teams_id
      })
    }

    const countdownTimer = () => {
      timeer.value = 120
      let countdown = 120
      const countdownInterval = setInterval(() => {
        countdown--
        if (countdown === 0) {
          clearInterval(countdownInterval)
          loading.value = false
        }
        timeer.value = countdown
      }, 1000)
    }

    watch(
      () => props.isConfirmPatment,
      value => {
        if (value) {
          editTel.value = true
          statusCallPaySolutions.value = false
          loading.value = false
          getTeamList()
          customerDataLocal.value = JSON.parse(JSON.stringify(props.customerData))
          orderDetailLocal.value = JSON.parse(JSON.stringify(props.orderDetail))
          fullname.value = customerDataLocal.value.customer_fname + ' ' + customerDataLocal.value.customer_lname
          if (!customerDataLocal.value.customer_email ||
            customerDataLocal.value.customer_email == '-' ||
            customerDataLocal.value.customer_email == '' ||
            customerDataLocal.value.customer_email == 'null') {
            email.value = 'apsth456@gmail.com'
          } else {
            email.value = customerDataLocal.value.customer_email
          }
        }
      },
    )
    watch(
      () => props.statusCreateOrder,
      value => {
        if (value) {
          confirmPayment()
        }
      },
    )

    const getStatusPaySolutions = time => {
      receipt
        .checkPayment(`?order_id_pri=${orderDetailLocal.value.order_id_pri}`)
        .then(res => {
          if (!res.data.status) {
            setTimeout(() => {
              if (props.isConfirmPatment) {
                getStatusPaySolutions2()
              }
            }, 3000)
          } else {
            emit('onAdd')
            emit('update:isConfirmPatment', false)
          }
        })
        .catch(err => {
          console.log('data err ponse', err)
        })
    }
    const getStatusPaySolutions2 = time => {
      receipt
        .checkPayment(`?order_id_pri=${orderDetailLocal.value.order_id_pri}`)
        .then(res => {
          if (!res.data.status) {
            setTimeout(() => {
              if (props.isConfirmPatment) {
                getStatusPaySolutions()
              }
            }, 3000)
          } else {
            store.commit('app/ALERT', {
              message: 'ทำรายการสำเร็จ',
              color: 'success',
            })
            emit('onAdd')
            emit('update:isConfirmPatment', false)
          }
        })
        .catch(err => {
          console.log('data err ponse', err)
        })
    }

    const confirmPayment = () => {
      receipt
        .paymentWithPaySolution({
          order_id_pri: orderDetailLocal.value.order_id_pri,
          customer_id_pri: customerDataLocal.value.customer_id_pri,
          customer_tel: customerDataLocal.value.customer_tel,
          customer_email: email.value,
          teams_id: commissionSelect.value,
        })
        .then(res => {
          if (res.response) {
            getStatusPaySolutions()
            countdownTimer()
            statusCallPaySolutions.value = true
            dataPaySolution.value = res.data
            qrcode.value = res.url
            customer_image.value = res.customer_image
          } else {
            store.commit('app/ALERT', {
              message: res.message,
              color: res.response ? 'success' : 'error',
            })
          }
        })
        .catch(err => {
          statusCallPaySolutions.value = true
          console.log('pay error', err)
        })
    }
    const payment = () => {
      const isFormValid = formPayment.value.validate()
      if (!isFormValid) return
      loading.value = true
      confirmPayment()
    }

    return {
      statusCallPaySolutions,
      fullname,
      editTel,
      dataPaySolution,
      orderDetailLocal,
      customerDataLocal,
      telValidator,
      required,
      formPayment,
      loading,
      payment,
      qrcode,
      customer_image,
      emailValidator,
      timeer,
      teamsAndUserList,
      commissionSelect,
      editEmail,
      email,
      icons: {
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang='scss' scoped></style>
